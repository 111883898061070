//useUpdateUserWorkspaces.ts
import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface UserWorkspaceUpdate {
    workspaceId: number;
}

export const useUpdateUserWorkspaces = () => {
    const [isUpdating, setIsUpdating] = useState(false);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const updateUserWorkspace = useCallback(async (updatedUserWorkspace: UserWorkspaceUpdate) => {
        if (!isAuthenticated) {
            console.error("User is not authenticated");
            return;
        }

        setIsUpdating(true);
        try {
            const token = await getAccessTokenSilently();
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';
            const url = `${apiUrl}/updateUserWorkspaces`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(updatedUserWorkspace),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log("User workspace updated successfully");
        } catch (error) {
            console.error("Error updating user workspace", error);
        } finally {
            setIsUpdating(false);
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    return { updateUserWorkspace, isUpdating };
};
