import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useVerifyToken = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [verificationResult, setVerificationResult] = useState(null);
    const [error, setError] = useState<string | null>(null);

    const verifyToken = useCallback(async (inviteToken: string, inviteeEmail: string) => {
        if (!isAuthenticated) {
            setError('Not authenticated');
            return;
        }

        try {
            const token = await getAccessTokenSilently();
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';

            let response = await fetch(`${apiUrl}/verifyToken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ inviteToken, inviteeEmail }),
            });

            if (response.ok) {
                const data = await response.json();
                setVerificationResult(data);
            } else {
                // Handle HTTP errors
                const errorData = await response.json();
                setError(errorData.message || `HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error("Error verifying token", error);
            setError('Error verifying token');
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    return { verifyToken, verificationResult, error };
};
