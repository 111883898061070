// views/Documents.tsx
import React, {useEffect, useContext, useRef} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useDocuments } from '../hooks/useDocuments';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { WorkspaceContext } from '../context/WorkspaceContext';
import { Button } from 'primereact/button';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Document } from '../types/Document';
import DocumentsMenu from '../components/DocumentsMenu';
import {useRequestApproval} from "../hooks/useRequestApproval";
import { Toast } from 'primereact/toast';
import Loading from "../components/Loading";
import { Skeleton } from 'primereact/skeleton';
import Docs from '../assets/icons/docs.svg';
import Slides from '../assets/icons/slides.svg';
import Figma from '../assets/icons/figma.svg';
import PowerPoint from '../assets/icons/power-point.svg';
import Word from '../assets/icons/word.svg';
import GraySparkles from '../assets/icons/gray-sparkles.svg';

const typeIconTemplate = (document: any) => {
    const icon = (() => {
        switch (document.type) {
            case 'Docs':
                return Docs;
            case 'Slides':
                return Slides;
            case 'Figma':
                return Figma;
            case 'PowerPoint':
                return PowerPoint;
            case 'Word':
                return Word;
            default:
                return Docs;
        }
    })();

    return (
        <a href={document.link} target="_blank" rel="noopener noreferrer">
            <img src={icon} alt={`${document.type} Icon`} className="icon-cell" />
        </a>
    );
};

const SparklesIcon = () => (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="https://www.w3.org/2000/svg">
        <path d="M14 15C14.5304 15 15.0391 15.2107 15.4142 15.5858C15.7893 15.9609 16 16.4696 16 17C16 16.4696 16.2107 15.9609 16.5858 15.5858C16.9609 15.2107 17.4696 15 18 15C17.4696 15 16.9609 14.7893 16.5858 14.4142C16.2107 14.0391 16 13.5304 16 13C16 13.5304 15.7893 14.0391 15.4142 14.4142C15.0391 14.7893 14.5304 15 14 15ZM14 3C14.5304 3 15.0391 3.21071 15.4142 3.58579C15.7893 3.96086 16 4.46957 16 5C16 4.46957 16.2107 3.96086 16.5858 3.58579C16.9609 3.21071 17.4696 3 18 3C17.4696 3 16.9609 2.78929 16.5858 2.41421C16.2107 2.03914 16 1.53043 16 1C16 1.53043 15.7893 2.03914 15.4142 2.41421C15.0391 2.78929 14.5304 3 14 3ZM7 15C7 13.4087 7.63214 11.8826 8.75736 10.7574C9.88258 9.63214 11.4087 9 13 9C11.4087 9 9.88258 8.36786 8.75736 7.24264C7.63214 6.11742 7 4.5913 7 3C7 4.5913 6.36786 6.11742 5.24264 7.24264C4.11742 8.36786 2.5913 9 1 9C2.5913 9 4.11742 9.63214 5.24264 10.7574C6.36786 11.8826 7 13.4087 7 15Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const copyToClipboard = (link: any) => {
    navigator.clipboard.writeText(link).then(() => {
        console.log('Link copied to clipboard');
    }).catch(err => {
        console.error('Could not copy text: ', err);
    });
};

const titleBodyTemplate = (rowData: any) => {
    return (
        <div className="title-body">
            <span>{rowData.title}</span>
            <a href={rowData.link} target="_blank" rel="noopener noreferrer" className="link-icon">
                <span className="pi pi-link table-actions"></span>
            </a>
            <span className="pi pi-copy table-actions" onClick={() => copyToClipboard(rowData.link)}></span>
        </div>
    );
};

const formatDate = (isoDate?: string): string => {
    if (!isoDate) {
        return '';
    }

    const date = new Date(isoDate);
    return date.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });
};

const dateTemplate = (rowData: any, field: string) => {
    return <span>{formatDate(rowData[field])}</span>;
};

const Documents: React.FC = () => {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const { workspace } = useContext(WorkspaceContext);
    const { documents, setDocuments, isLoading } = useDocuments(workspace?.workspaceId);
    const { requestApproval} = useRequestApproval();
    const toast = useRef<Toast>(null);
    const statusBodyTemplate = (document: any) => {
        let className;
        switch (document.status) {
            case 'New':
                className = 'tag-new';
                break;
            case 'Approved':
                className = 'tag-approved';
                break;
            case 'Pending':
                className = 'tag-pending';
                break;
            default:
                className = '';
                break;
        }

        return <span className={`tag ${className}`}>{document.status}</span>;
    };

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const submitForApproval = async (documentId: number) => {
        try {
            await requestApproval(documentId);
            const updatedDocuments = documents.map(doc => {
                if (doc.documentId === documentId) {
                    return { ...doc, status: 'Pending' };
                }
                return doc;
            });
            setDocuments(updatedDocuments);
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Document submitted for approval.', life: 3000 });
        } catch (error) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to submit document for approval.', life: 3000 });
        }
    };

    const renderContent = () => {
        if (isLoading) {
            // Render loading skeletons
            return (
                <div>
                    {/* Adjust the number of Skeletons based on your layout needs */}
                    <Skeleton shape="rectangle" width="100%" height="2rem" className="mb-2" />
                    <Skeleton shape="rectangle" width="100%" height="2rem" className="mb-2" />
                    <Skeleton shape="rectangle" width="100%" height="10rem" className="mb-2" />
                </div>
            );
        } else if (!isLoading && documents.length === 0) {
            // Render "No documents" message
            return (
                <div className="flex flex-column align-items-center text-center mt-4 mb-8">
                    <img src={GraySparkles} alt="Gray Sparkles"/>
                    <span className="font-semibold text-lg">No documents yet</span>
                    <span className="max-w-16rem">Use MamboAI in your first Google Doc to get started.</span>
                    <a href="https://workspace.google.com/marketplace/app/mambo_ai/959880682056" target="_blank" rel="noreferrer">Learn more.</a>
                    <div className="tooltip-container mt-4 mb-1 float-animation">
                        <span className="tooltip-always-on ">Start here</span>
                        <div className="tooltip-caret"></div>
                    </div>
                    <Button label="Add MamboAI to Google Docs" icon={<SparklesIcon/>}/>
                </div>
            );
        } else {
            // Render documents table
            return (
                <>
                    <div className="page-header">
                        <h3>Documents</h3>
                        <p>List of documents submitted for review</p>
                    </div>
                    <DataTable value={documents} sortMode="multiple" paginator rows={6} emptyMessage="No documents found">
                        <Column field="type" body={typeIconTemplate}></Column>
                        <Column header="Title" body={titleBodyTemplate} sortable className="title-column"></Column>
                        <Column field="status" header="Status" body={statusBodyTemplate} sortable></Column>
                        <Column field="submitter" header="Submitter" sortable></Column>
                        <Column field="approver" header="Approver" sortable></Column>
                        <Column field="dateSubmitted" header="Date Submitted" body={rowData => dateTemplate(rowData, 'dateSubmitted')} sortable></Column>
                        <Column field="dateApproved" header="Date Approved" body={rowData => dateTemplate(rowData, 'dateApproved')} sortable></Column>
                        <Column
                            body={(rowData: Document) => (
                                <DocumentsMenu document={rowData} onSubmit={submitForApproval} />
                            )}
                            headerStyle={{ width: '90px', textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                        />

                    </DataTable>
                    <Toast ref={toast} />
                </>
            );
        }
    };

    return (
        <div>
            {renderContent()}
            <Toast ref={toast}/>
        </div>
    );
};

export default withAuthenticationRequired(Documents, {
    onRedirecting: () => <Loading />,
});
