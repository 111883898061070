// hooks/useMembers.ts
import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Member } from '../types/Member';
export const useMembers = (workspaceId?: number, refreshTrigger?: any) => {
    const [members, setMembers] = useState<Member[]>([]);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

        const fetchMembers = useCallback(async () => {
            if (!isAuthenticated || workspaceId === undefined) {
                return;
            }

            try {
                const token = await getAccessTokenSilently();
                const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';
                const url = `${apiUrl}/members?workspaceId=${workspaceId}`;

                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data: Member[] = await response.json();
                setMembers(data)
            } catch (error) {
                console.error("Error fetching member data", error);
            }
    }, [isAuthenticated, getAccessTokenSilently, workspaceId]);

    // Function to update a member
    const updateMember = async (updatedMember: Member) => {
        if (!isAuthenticated) {
            return;
        }
        try {
            const token = await getAccessTokenSilently();
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';
            const url = `${apiUrl}/members?workspaceId=${updatedMember.workspaceId}`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(updatedMember),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Optionally, refresh member list after update
            await fetchMembers();
        } catch (error) {
            console.error("Error updating member", error);
        }
    };

    // Fetch members on mount and when dependencies change
    useEffect(() => {
        fetchMembers();
    }, [fetchMembers]);

    return { members, updateMember };
};
