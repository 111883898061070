//useUser.ts
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from '../types/User';

export const useUser = () => {
    const [profile, setProfile] = useState<User>();
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        const fetchProfile = async () => {
            try {
                const token = await getAccessTokenSilently();
                const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';

                // Fetch user profile
                const userData = {
                    auth0SubId: user?.sub,
                    name: user?.name,
                    email: user?.email,
                    givenName: user?.given_name,
                    familyName: user?.family_name,
                    nickname: user?.nickname,
                    picture: user?.picture,
                };
                console.log("userData:", userData)
                let response = await fetch(`${apiUrl}/user`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setProfile(data);

                    await fetch(`${apiUrl}/user`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(userData),
                    });

                } else if (response.status === 404) {
                    // User does not exist, create new user
                    const newUser = {
                        auth0SubId: user?.sub,
                        name: user?.name,
                        email: user?.email,
                        givenName: user?.given_name,
                        familyName: user?.family_name,
                        nickname: user?.nickname,
                        picture: user?.picture,
                    };

                    response = await fetch(`${apiUrl}/user`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(newUser),
                    });

                    if (!response.ok) {
                        throw new Error(`Error creating new user: ${response.status}`);
                    }

                    const data = await response.json();
                    setProfile(data);
                } else {
                    // Handle other HTTP errors
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error("Error fetching or creating profile data", error);
            }
        };

        fetchProfile();
    }, [isAuthenticated, getAccessTokenSilently, user]);

    return profile;
};
