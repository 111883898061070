// CreateWorkspaceDialog.tsx
import React, {useState} from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import { useUploadImage } from "../hooks/useUploadImage";
import { useCreateWorkspace} from "../hooks/useCreateWorkspace";
import workspaceDefaultImage  from '../assets/images/workspace-default.png';

interface CreateWorkspaceDialogProps {
    visible: boolean;
    onHide: () => void;
}

const CreateWorkspaceDialog: React.FC<CreateWorkspaceDialogProps> = ({ visible, onHide }) => {
    const [workspaceName, setWorkspaceName] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [logoURL, setLogoURL] = useState('');
    const { getUploadURL, uploadImage } = useUploadImage();
    const { createWorkspace, createResult, isLoading, error } = useCreateWorkspace();

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setSelectedFile(file);
            setLogoURL(URL.createObjectURL(file));
            await getUploadURL(file.name, file.type, "workspace-logos");
        }
    };

    const handleSaveChanges = async () => {
        let finalLogoURL = logoURL;
        if (selectedFile) {
            const uploadedLogoURL = await uploadImage(selectedFile);
            finalLogoURL = uploadedLogoURL || workspaceDefaultImage;
        }
        if (!finalLogoURL) {
            finalLogoURL = workspaceDefaultImage;
        }
        await createWorkspace(workspaceName, finalLogoURL, 1);

        if (!error) {
            console.log(createResult);
            onHide();
        } else {
            console.error(error);
        }
    };

    return (
        <Dialog
            header="Let's create a workspace"
            visible={visible}
            style={{ width: '50vw' }}
            onHide={onHide}
            closable={true}
            modal={true}
            position={'center'}
            headerClassName={'dialog-header-center'}
        >
            <div className="subheader">
                Once you create a workspace, you'll be able to start using <strong>Mambo AI ✨</strong> to track document approvals.
            </div>
            <div className="dialog-form">
                <div className="dialog-form-row mt-2">
                    <label>Logo</label>
                    <input type="file" onChange={handleFileSelect} style={{ display: 'none' }} id="file-upload" />
                    <label htmlFor="file-upload" className="max-w-5rem">
                        <div className="upload-logo-container">
                            <div className="workspace-logo-container">
                                <img src={logoURL || workspaceDefaultImage} alt="Workspace Logo" className="workspace-logo" />
                            </div>
                            <div className="logo-upload">
                                <span className="upload-text">Upload</span>
                            </div>
                        </div>
                    </label>
                    <small id="logo-help">
                        Add a logo for your workspace. Recommended size is 256x256px.
                    </small>
                </div>
                <div className="dialog-form-row mt-4">
                    <label htmlFor="workspaceName">Workspace Name</label>
                    <InputText
                        id="workspaceName"
                        value={workspaceName}
                        onChange={(e) => {setWorkspaceName(e.target.value);}}
                    />
                </div>
                <div className="settings-row mt-4">
                    <Button label="Create Workspace" onClick={handleSaveChanges} loading={isLoading} disabled={isLoading}/>
                </div>
            </div>
        </Dialog>
    );
};

export default CreateWorkspaceDialog;
