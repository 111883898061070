// src/utils/utils.ts

/**
 * Calculates the number of days until the trial period ends.
 * @param {string} trialEndDate - The end date of the trial period.
 * @returns {number} The number of days until the trial ends. Returns a default future date string if input is falsy.
 */
export const getDaysUntilTrialEnd = (trialEndDate: string): number | string => {
    const trialEnd = new Date(trialEndDate);
    const now = new Date();
    const timeDiff = trialEnd.getTime() - now.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
};
