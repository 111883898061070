// config/config.ts
interface Config {
  domain: string;
  clientId: string;
  audience?: string;
  isBillingEnabled: boolean;
  debugEnabled: boolean;
}
export function getConfig(): Config {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || "defaultDomain";
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "defaultClientId";
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE !== "YOUR_API_IDENTIFIER"
      ? process.env.REACT_APP_AUTH0_AUDIENCE
      : null;

  const isBillingEnabled = process.env.REACT_APP_BILLING_FEATURE === 'true';
  const debugEnabled = process.env.REACT_APP_DEBUG === 'true';

  return {
    domain,
    clientId,
    ...(audience && { audience }),
    isBillingEnabled,
    debugEnabled
  };
}
