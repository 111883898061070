import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import '../assets/styles/invite.scss';
import { Button } from 'primereact/button';
const Invite = () => {
    const { loginWithRedirect } = useAuth0();
    const { token } = useParams();

    useEffect(() => {
        if (token) {
            localStorage.setItem('inviteToken', token);
        }
    }, [token]);

    const handleSignupLink = () => {
        // Redirect to the Auth0 login or sign-up page
        loginWithRedirect({
            authorizationParams: {
                screen_hint: "signup",
                // Include any other parameters you need here
            },
        });
    };

    const handleSigninLink = () => {
        // Redirect to the Auth0 login or sign-up page
        loginWithRedirect({
            authorizationParams: {
                screen_hint: "login",
                // Include any other parameters you need here
            },
        });
    };

    return (
        <div className="invite-page pt-4">
            <div className="pb-6">
                <h1>Join <span className="highlight">your team</span> on Mambo ✨</h1>
                <p className="sub-heading pt-2">Mambo AI is a compliance tool that helps you get your work done faster.</p>
                {/*<img src="https://lh3.googleusercontent.com/a/ACg8ocJeYq_jUqCKFaUCdLbFG2-ABLtch58ZdpDS8xiREOjkOA=s96-c" alt="Hussein Yahfoufi"/>
                <p className="friends-text pt-1">Hussein is waiting for you</p>*/}
            </div>
{/*
            <p className="invite">You're accepting an invitation sent to <strong>hussein@mamboai.com</strong></p>
*/}
            <Button onClick={handleSignupLink}>Sign up and join</Button>
            <div onClick={handleSigninLink} className="text-link block">Login and join</div>
            <p className="disclosure">By continuing, you’re agreeing to our <a href="https://www.mamboai.com/terms" target="_blank" rel="noreferrer">Terms of Service</a>.
                Additional disclosures are available in our <a href="https://www.mamboai.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>.</p>
        </div>
    );
};

export default Invite;
