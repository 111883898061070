//NavBar.tsx
import React, { useState, useEffect, useContext } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useUpdateUserWorkspaces} from "../hooks/useUpdateUserWorkspaces";
import { WorkspaceContext } from '../context/WorkspaceContext';
import { getConfig } from '../config/config';
import { getDaysUntilTrialEnd } from '../utils/utils';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, NavLink,
} from "reactstrap";

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import { useAuth0 } from "@auth0/auth0-react";
import {WorkspaceType} from "../types/Workspace";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { workspace, setWorkspace, workspaces } = useContext(WorkspaceContext);
  const { isBillingEnabled } = getConfig();

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceType | null>(null);

  useEffect(() => {
    setSelectedWorkspace(workspace);
  }, [workspace]);

  const onWorkspaceChange = (e: any) => {
    setSelectedWorkspace(e.value);
    handleWorkspaceSelect(e.value);
  };
  const workspaceOptions = workspaces.map((workspace: WorkspaceType) => ({
    label: workspace.workspaceName,
    value: workspace
  }));
  const workspaceTemplate = (option: any) => {
    return (
        <div className="workspace-info">
          <img src={option.value.logoURL} alt={option.value.workspaceName} className="workspace-logo" />
          <span className="workspace-name">{option.label}</span>
        </div>
    );
  };
  const selectedWorkspaceTemplate = (option: any) => {
    if (!option) return <span>Select a workspace</span>;

    return (
        <div className="workspace-info">
          <img src={option.value.logoURL} alt={option.value.workspaceName} className="workspace-logo" />
          <span className="workspace-name">{option.label}</span>
        </div>
    );
  };
  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });
  const { updateUserWorkspace } = useUpdateUserWorkspaces();

  const handleWorkspaceSelect = async (workspace: WorkspaceType) => {
    setWorkspace(workspace);
    await updateUserWorkspace({ workspaceId: workspace.workspaceId });
    console.log("Selected workspace:", workspace);
  };

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md" container={false}>
        <div className="container">
          <span className="wordmark">MAMBO AI ✨</span>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto align-items-center" navbar>
              {isAuthenticated && selectedWorkspace && (
                  <>
                    <NavItem className="md-hide">
                      <NavLink
                          tag={RouterNavLink}
                          to="/documents"
                          exact={"true"}
                      >
                        Documents
                      </NavLink>
                    </NavItem>
                    {selectedWorkspace.status !== 'active' && isBillingEnabled && (
                        <>
                          <NavItem className="md-hide">
                            <div>
                              {new Date(selectedWorkspace.trialEnd) < new Date() ?
                                <NavLink to="/plan">
                                  <span className="mambo-chip warning">Your trial ended</span>
                                </NavLink> :
                                <NavLink to="/plan">
                                  <span className="mambo-chip warning">
                                    <span>Expires in <strong>{getDaysUntilTrialEnd(selectedWorkspace.trialEnd)} days</strong></span>
                                  </span>
                                </NavLink>
                              }
                            </div>
                          </NavItem>
                        </>
                    )}
                  </>
              )}
            </Nav>
            <Nav className="navbar-right md-hide" navbar>
              {isAuthenticated && workspaces && workspaces.length > 0 && (
                  <NavItem>
                    <Dropdown
                        value={workspace}
                        options={workspaceOptions}
                        onChange={onWorkspaceChange}
                        placeholder="Select a workspace"
                        itemTemplate={workspaceTemplate}
                        valueTemplate={selectedWorkspaceTemplate}
                        className="w-200"
                    />
                  </NavItem>
              )}
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown" className="md-hide">
                    <img
                      src={user?.picture}
                      alt="User"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user?.name}</DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/profile"
                      className="dropdown-profile"
                    >
                      <span className="pi pi-user mr-3"></span> User
                    </DropdownItem>
                    { isBillingEnabled && (
                        <>
                        <DropdownItem
                          tag={RouterNavLink}
                          to="/billing"
                          className="dropdown-profile"
                      >
                        <span className="pi pi-credit-card mr-3"></span> Billing
                      </DropdownItem>
                    <DropdownItem
                        tag={RouterNavLink}
                        to="/plan"
                        className="dropdown-profile"
                    >
                      <span className="pi pi-briefcase mr-3"></span> Manage plan
                    </DropdownItem>
                        </>
                  )}
                    <DropdownItem
                        tag={RouterNavLink}
                        to="/workspace"
                        className="dropdown-profile"
                    >
                      <span className="pi pi-users mr-3"></span> Workspace settings
                    </DropdownItem>
                    <DropdownItem
                        tag={RouterNavLink}
                        to="/members"
                        className="dropdown-profile"
                    >
                      <span className="pi pi-user-plus mr-3"></span> Invite & manage members
                    </DropdownItem>
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <span className="pi pi-power-off mr-3"></span> Log out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                style={{ minHeight: 170 }}
              >
                <NavItem>
                  <span className="user-info">
                    <img
                      src={user?.picture}
                      alt="User"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user?.name}</h6>
                  </span>
                </NavItem>
                <NavItem>
                    <Dropdown
                      value={workspace}
                      options={workspaceOptions}
                      onChange={onWorkspaceChange}
                      placeholder="Select a workspace"
                      itemTemplate={workspaceTemplate}
                      valueTemplate={selectedWorkspaceTemplate}
                      />
                </NavItem>
                <NavItem>
                  <span className="pi pi-file mr-3"></span>
                  <RouterNavLink
                      to="/documents"
                  >
                    Documents
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <span className="pi pi-user mr-3"></span>
                  <RouterNavLink
                    to="/profile"
                  >
                    User
                  </RouterNavLink>
                </NavItem>
                {isBillingEnabled && (
                <><NavItem>
                  <span className="pi pi-credit-card mr-3"></span>
                  <RouterNavLink
                      to="/billing"
                  >
                    Billing
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <span className="pi pi-briefcase mr-3"></span>
                  <RouterNavLink
                      to="/plan"
                  >
                    Manage plan
                  </RouterNavLink>
                </NavItem></>
                  )}

                <NavItem>
                  <span className="pi pi-users mr-3"></span>
                  <RouterNavLink
                      to="/workspace"
                  >
                    Workspace settings
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <span className="pi pi-user-plus mr-3"></span>
                  <RouterNavLink
                      to="/members"
                  >
                    Invite & manage members
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <span className="pi pi-power-off mr-3"></span>
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default NavBar;
