// views/Members.tsx
import React, { useContext, useState } from "react";
import { useMembers } from '../hooks/useMembers';
import { DataView } from 'primereact/dataview';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import InviteMemberDialog from "../components/InviteMemberDialog";
import { WorkspaceContext } from '../context/WorkspaceContext';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import MemberItem from '../components/MemberItem';
import {Member} from "../types/Member";


export const Members = () => {
    const { workspace: selectedWorkspace } = useContext(WorkspaceContext);
    const [refreshMembers, setRefreshMembers] = useState(false);
    const { members, updateMember } = useMembers(selectedWorkspace?.workspaceId, refreshMembers);
    const [isInviteDialogVisible, setInviteDialogVisible] = useState<boolean>(false);

    if (!selectedWorkspace) {
        return <div>Loading...</div>;
    }

    const handleInviteMember = () => {
        setInviteDialogVisible(true);
    };

    const handleInvitation = (email: string) => {
        console.log("Inviting member:", email);
        // Implement the logic to invite the member
        setInviteDialogVisible(false);
    };

    const handleMemberUpdate = async (updatedMember: Member) => {
        try {
            await updateMember(updatedMember);
            setRefreshMembers(prev => !prev); // Refresh the members list
        } catch (error) {
            console.error("Error updating member", error);
            // Optionally, show an error message to the user
        }
    };

    const refreshMembersList = () => {
        setRefreshMembers(prev => !prev);
    };

    if (!members || members.length === 0) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="page-header">
                <h3>Members</h3>
                <p>Manage members of your workspace</p>
            </div>
            <Card className="members-card">
                <div className="card-top-info">
                    <span>{members.length} members</span>
                    <Button label="Invite members" onClick={handleInviteMember} />
                </div>
                <DataView value={members} itemTemplate={member => <MemberItem member={member} onMemberUpdate={handleMemberUpdate}/>} paginator rows={10} />
            </Card>
            <InviteMemberDialog
                visible={isInviteDialogVisible}
                onHide={() => setInviteDialogVisible(false)}
                onInvite={handleInvitation}
                workspaceId={selectedWorkspace.workspaceId}
                onInviteSuccess={refreshMembersList}
            />
        </div>
    );
};

export default withAuthenticationRequired(Members, {
    onRedirecting: () => <Loading />,
});
