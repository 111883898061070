import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Subscription } from '../types/Subscription';

export const useSubscription = (subscriptionId?: number) => {
    const [subscription, setSubscription] = useState<Subscription[] | null>(null);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated || subscriptionId === undefined) {
            return;
        }

        const fetchSubscription = async () => {
            try {
                const token = await getAccessTokenSilently();
                const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';
                const url = `${apiUrl}/subscriptions?subscriptionId=${subscriptionId}`;

                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data: Subscription[] = await response.json();
                setSubscription(data);
            } catch (error) {
                console.error("Error fetching subscription data", error);
            }
        };

        fetchSubscription();
    }, [isAuthenticated, getAccessTokenSilently, subscriptionId]);

    return subscription;
};

