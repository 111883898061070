// hooks/useUpdateUser.ts
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { User, UserUpdate } from '../types/User';

export const useUpdateUser = () => {
    const [isUpdating, setIsUpdating] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const updateUser = async (token: string, updatedUser: UserUpdate) => {
        setIsUpdating(true);
        try {
            const token = await getAccessTokenSilently();
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';
            const url = `${apiUrl}/updateUser`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(updatedUser),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const updatedUserData: User = await response.json();
            return updatedUserData;

        } catch (error) {
            console.error("Error updating user", error);
            throw error;
        } finally {
            setIsUpdating(false);
        }
    };

    return { updateUser, isUpdating };
};
