// App.tsx
import React, {useContext, useEffect, useState} from "react";
import { useAuth0, Auth0Provider } from "@auth0/auth0-react";
import {BrowserRouter as Router, Route, Routes, useNavigate} from "react-router-dom";

// components
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import {WorkspaceContext, WorkspaceProvider} from './context/WorkspaceContext';

// views
import Billing from './views/Billing';
import Documents from './views/Documents';
import Home from "./views/Home";
import Member from './views/Members';
import Plan from './views/Plan';
import Profile from "./views/Profile";
import Workspace from './views/Workspace';
import Invite from './views/Invite';

// hooks
import {useVerifyToken} from "./hooks/useVerifyToken";
import {useUser} from "./hooks/useUser";

// styles
import "./assets/styles/theme.scss"

const RedirectionHandler = () => {
  const { workspace } = useContext(WorkspaceContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (workspace) {
      const trialEnd = new Date(workspace.trialEnd);
      const now = new Date();
      if (trialEnd < now && workspace.status !== 'active') {
        navigate('/plan');
      }
    }
  }, [workspace, navigate]);

  return null;
};
const App = () => {
    const { isLoading, isAuthenticated, user } = useAuth0();
    const { verifyToken } = useVerifyToken();
    const [isTokenVerified, setIsTokenVerified] = useState(false);
    useUser();

  useEffect(() => {
    setIsTokenVerified(true);
    if (isAuthenticated && user && user.email) {
      const inviteToken = localStorage.getItem('inviteToken');
      if (inviteToken) {
        setIsTokenVerified(false);
        verifyToken(inviteToken, user.email)
            .then(verificationResult => {
              console.log('Token verified successfully:', verificationResult);
            })
            .catch(error => {
              console.error('Token verification error:', error);
            })
            .finally(() => {
              localStorage.removeItem('inviteToken');
              setIsTokenVerified(true);
            });
      }
    }
  }, [isAuthenticated, user, verifyToken]);

  if (isLoading) {
    return <Loading />;
  }

  return (
      <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN!}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
          }}
      >
        <WorkspaceProvider>
        <Router>
          <div id="app" className="d-flex flex-column h-100">
              {isTokenVerified && <NavBar />}
              <div className="container flex-grow-1 mt-5">
              <Routes>
                <Route path="/billing" element={<><RedirectionHandler /><Billing /></>} />
                <Route path="/" element={<><RedirectionHandler /><Home /></>} />
                <Route path="/profile" element={<><RedirectionHandler /><Profile /></>} />
                <Route path="/documents" element={<><RedirectionHandler /><Documents /></>} />
                <Route path="/workspace" element={<><RedirectionHandler /><Workspace /></>} />
                <Route path="/members" element={<><RedirectionHandler /><Member /></>} />
                <Route path="/plan" element={<><RedirectionHandler /><Plan /></>} />
                <Route path="/invite/:token" element={<Invite />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
        </WorkspaceProvider>
      </Auth0Provider>
  );
};

export default App;
