//hooks/useCancelSubscription.ts
import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useCancelSubscription = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [cancelResult, setCancelResult] = useState(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const cancelSubscription = useCallback(async (workspaceId: number) => {
        if (!isAuthenticated) {
            setError('Not authenticated');
            return;
        }

        setIsLoading(true);
        try {
            const token = await getAccessTokenSilently();
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';

            let response = await fetch(`${apiUrl}/cancelSubscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ workspaceId }),
            });

            if (response.ok) {
                const data = await response.json();
                setCancelResult(data);
            } else {
                const errorData = await response.json();
                setError(errorData.message || `HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error("Error cancelling subscription", error);
            setError('Error cancelling subscription');
        } finally {
            setIsLoading(false);
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    return { cancelSubscription, cancelResult, error, isLoading };
};
