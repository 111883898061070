// views/Home.tsx
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import mamboLogo from "../assets/icons/icon-rounded.png";
import Loading from "../components/Loading";

const Home = () => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const navigate = useNavigate();
    const [authChecking, setAuthChecking] = useState(true);

    useEffect(() => {
        if (!isLoading) {
            if (isAuthenticated) {
                navigate('/documents');
            } else {
                setAuthChecking(false);
            }
        }
    }, [isAuthenticated, isLoading, navigate]);

    if (isLoading || authChecking) {
        return <Loading />;
    }

    return (
        <div className="text-center hero">
            <img src={mamboLogo} alt="Mambo AI Icon" className="login-logo" />
            <div className="login-buttons mt-4">
                <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin block m-auto"
                    onClick={() => loginWithRedirect({})}
                >
                    Continue to log in
                </Button>
                <Button
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin block m-auto mt-3"
                    onClick={() => loginWithRedirect({
                        authorizationParams: {
                            screen_hint: "signup",
                        },
                    })}
                >
                    Need to sign up?
                </Button>
            </div>
        </div>
    );
};

export default Home;
