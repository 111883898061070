// useWorkspace.ts
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { WorkspaceType, WorkspaceTypeUpdate } from '../types/Workspace';
export const useWorkspaces = () => {
    const [workspaces, setWorkspaces] = useState<WorkspaceType[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const updateWorkspace = async (updatedWorkspace: WorkspaceTypeUpdate) => {
        try {
            const token = await getAccessTokenSilently();
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';
            const url = `${apiUrl}/workspaces`;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(updatedWorkspace),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Update the local state with the new workspace data
            setWorkspaces(prevWorkspaces =>
                prevWorkspaces.map(ws =>
                    ws.workspaceId === updatedWorkspace.workspaceId ? { ...ws, ...updatedWorkspace } : ws
                )
            );

        } catch (error) {
            console.error("Error updating workspace", error);
        }
    };

    useEffect(() => {
        if (!isAuthenticated) {
            setIsLoading(false);
            return;
        }

        const fetchWorkspaces = async () => {
            setIsLoading(true);
            try {
                const token = await getAccessTokenSilently();
                const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';
                const url = `${apiUrl}/workspaces`;

                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data: WorkspaceType[] = await response.json();
                setWorkspaces(data)
            } catch (error) {
                console.error("Error fetching workspace data", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchWorkspaces();
    }, [isAuthenticated, getAccessTokenSilently]);

    return { workspaces, isLoading, updateWorkspace };
};
