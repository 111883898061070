import React, {useContext, useEffect, useState} from "react";
import { WorkspaceContext } from '../context/WorkspaceContext';
import { useSubscription } from '../hooks/useSubscriptions';
import { useCancelSubscription } from '../hooks/useCancelSubscription';
import {CurrencySymbols} from "../utils/currencySymbols";
import '../assets/styles/billing.scss';
import { withAuthenticationRequired } from "@auth0/auth0-react";

import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import check from "../assets/icons/check-simple.svg";
import {IconBrandMastercard, IconBrandVisa, IconCreditCard} from '@tabler/icons-react';
import { IconDownload } from '@tabler/icons-react';
import {useBilling} from "../hooks/useBilling";
import {PaymentDetails, Invoices} from "../types/Billing";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Loading from "../components/Loading";

export const Billing = () => {
    const { workspace: selectedWorkspace } = useContext(WorkspaceContext);
    const { cancelSubscription, error } = useCancelSubscription();
    const subscriptions = useSubscription(selectedWorkspace?.subscriptionId || 0);
    const subscription = subscriptions?.find(sub => sub.subscriptionId === selectedWorkspace?.subscriptionId);
    const workspaceId = selectedWorkspace?.workspaceId || 0;

    // Fetch billing details
    const billingDetails = useBilling(workspaceId);
    const [paymentDetails, setPaymentDetails] = useState<PaymentDetails | null>(null);
    const [invoices, setInvoices] = useState<Invoices[]>([]);

    useEffect(() => {
        if (billingDetails.length > 0) {
            // Assuming the first item in the array is the current billing data
            const currentBilling = billingDetails[0];
            setPaymentDetails(currentBilling.paymentDetails);
            setInvoices(currentBilling.invoices);
        }
    }, [billingDetails]);

    // Calculate the progress bar value
    const progressBarValue = subscription && subscription.monthlyCredits > 0
        ? (subscription.creditsUsed / subscription.monthlyCredits) * 100
        : 0;

    const CreditCardIcons = {
        MasterCard: <IconBrandMastercard size={48} />,
        Visa: <IconBrandVisa size={48} />,
        Discover: <IconCreditCard size={48} />,
        JCB: <IconCreditCard size={48} />,
        'American Express': <IconCreditCard size={48} />,
        'Diners Club': <IconCreditCard size={48} />,
        'Eftpos Australia': <IconCreditCard size={48} />,
        'Union Pay': <IconCreditCard size={48} />,
        Unknown: <IconCreditCard size={48} />,
    };

    // Handle account cancellation confirmation
    const confirmCancelAccount = () => {
        confirmDialog({
            message: 'Are you sure you want to cancel your Mambo AI account?',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => handleAccountCancellation(),
            reject: () => {/* Optional: any logic on rejection */}
        });
    };

    const handleAccountCancellation = async () => {
        if (selectedWorkspace?.workspaceId) {
            await cancelSubscription(selectedWorkspace.workspaceId);
            if (!error) {
                console.log('Subscription cancellation successful');
                // Optionally, update UI or state based on successful cancellation
            } else {
                console.error('Error cancelling subscription:', error);
                // Handle error case, such as displaying a notification to the user
            }
        }
    };

    if (!selectedWorkspace || !subscription) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="page-header">
                <h3>Billing</h3>
                <p>Manage your billing information and update your plan. For questions about billing, contact <a href="mailto:hey@mamboai.com">hey@mamboai.com</a>.</p>
            </div>
            <Card title="Plan details">
                <div className="flex flex-row justify-content-between">
                    <div className="text-sm text-color-secondary">Plan <br /><span className="text-base font-semibold text-gray-900">{subscription.plan}</span></div>
                    <div className="text-sm text-color-secondary">Users <br /><span className="text-base font-semibold text-gray-900">{subscription.users}</span></div>
                    <div className="text-sm text-color-secondary">Credits <br /><span className="text-base font-semibold text-gray-900">{subscription.monthlyCredits}</span></div>
                    <div className="text-sm text-color-secondary">Cost <br />
                        <span className="text-base font-semibold text-gray-900">{CurrencySymbols[subscription.currency.toUpperCase()]}{subscription.cost.toLocaleString()}/{subscription.paymentInterval}
                            {subscription.paymentInterval === 'month' && <a href="/plan" className="text-sm font-medium text-blue-600">(get 1 free month)</a>}
                        </span>
                    </div>
                </div>
                <Card className="mt-4">
                    <div className="font-semibold">Plan usage</div>
                    <div>Usage resets on {new Date(subscription.currentPeriodEnd).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div>
                    <div className="mt-4 mb-2 flex justify-content-between">
                        <span>Credits</span>
                        <span><span className="text-gray-900">{subscription.creditsUsed}</span> / {subscription.monthlyCredits}</span>
                    </div>
                    <ProgressBar value={progressBarValue} className="transparent mb-3"/>
                    <div><span className="text-gray-900 font-semibold">{subscription.creditsUsed}</span> credits used</div>
                </Card>
                <div className="font-bold mt-5 text-gray-900">Plan features</div>
                <ul className="custom-svg-list">
                    <li><img src={check} alt="check"/> {subscription.monthlyCredits} credits per month<a href="/plan" className="text-sm font-medium text-blue-600">(add credits)</a></li>
                    <li><img src={check} alt="check"/> {subscription.users} users<a href="/plan" className="text-sm font-medium text-blue-600">(add users)</a></li>
                    <li><img src={check} alt="check"/> Unlimited documents</li>
                    <li><img src={check} alt="check"/> Friendly support waiting to help you</li>
                </ul>
                {subscription.cancellationDate ?
                    <div className="text-red-500">
                        {"Cancelling on " + new Date(subscription.cancellationDate).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </div>
                    :
                    <div className="text-red-500 text-link" onClick={confirmCancelAccount}>Cancel Mambo AI account</div>
                }
            </Card>
            <div className="flex mt-4 mb-6">
                <div className="flex col-6 mr-3 mambo-card-container dark flex-column">
                    <div className="card-header-row">
                        <span className="card-title">Payment Details</span>
                        <span className="card-action">Edit</span>
                    </div>
                    <div className="credit-card-container">
                        {paymentDetails && (
                            <>
                                <div className="card-icon">
                                    {CreditCardIcons[paymentDetails.ccType as keyof typeof CreditCardIcons] || <IconCreditCard size={48} />}
                                </div>
                                <div className="card-details">
                                    <div className="card-number">•••• •••• •••• {paymentDetails.ccLastFour}</div>
                                    <div className="card-info">
                                        <span className="card-type">{paymentDetails.ccType}</span> –
                                        <span className="card-expiry">Expires {paymentDetails.ccExpiration}</span>
                                    </div>
                                </div>
                            </>
                        )}
                        {!paymentDetails && <div>Coming Soon.</div>}
                    </div>
                    <div className="mt-4 mb-8 pl-4">
                         {paymentDetails &&
                             <><span className="next-payment-text">Next payment:</span>
                                 <span className="payment-amount">${paymentDetails.nextPaymentAmount} on </span>
                                 <span
                                     className="payment-date">{new Date(paymentDetails.currentPeriodEnd).toLocaleDateString('en-US', {
                                     year: 'numeric',
                                     month: 'long',
                                     day: 'numeric'
                                 })}</span>
                             </>
                         }
                    </div>
                </div>
                <div className="flex col-6 mr-3 mambo-card-container dark flex-column">
                    <div className="card-header-row">
                        <span className="card-title">Invoices</span>
                        <span className="card-action">View all</span>
                    </div>
                    <div className="invoice-list flex flex-column">
                        {invoices.length > 0 ? (
                            invoices.map((invoice, index) => (
                                <div key={index} className="invoice-item">
                                    <span>{new Date(invoice.invoiceCreatedDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                    <div>
                                        <span className="mr-3 invoice-amount">${invoice.invoiceAmountDue}</span>
                                        <a href={invoice.hostedInvoiceURL} target="_blank" rel="noopener noreferrer" className="pr-2"><IconDownload color={"#4A90E2"}/></a>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div>Coming Soon.</div>
                        )}
                    </div>
                </div>
            </div>
            <ConfirmDialog />
        </div>
    );
};

export default withAuthenticationRequired(Billing, {
    onRedirecting: () => <Loading />,
});