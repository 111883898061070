// views/Profile.tsx
import React, { useState, useEffect, useRef } from "react";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { Toast } from 'primereact/toast';
import { useUpdateUser } from "../hooks/useUpdateUser";
import { useUploadImage } from "../hooks/useUploadImage";
import Highlight from "../components/Highlight";
import { UserUpdate } from "../types/User";
import { getConfig } from '../config/config';

export const Profile = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const { updateUser } = useUpdateUser();
    const { getUploadURL, uploadImage } = useUploadImage();
    const toast = useRef<Toast>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const config = getConfig();
    const [updatedUser, setUpdatedUser] = useState({
        givenName: user?.given_name || '',
        familyName: user?.family_name || '',
        email: user?.email || '',
        picture: user?.picture || '',
    });
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    useEffect(() => {
        setUpdatedUser({
            givenName: user?.given_name || '',
            familyName: user?.family_name || '',
            email: user?.email || '',
            picture: user?.picture || '',
        });
    }, [user]);

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log("Selected file:", file);
            setSelectedFile(file);
            await getUploadURL(file.name, file.type, "user-avatars");
        }
    };

    // Check if the user is using social login
    const isSocialLogin = user?.sub?.startsWith('google-oauth2');

    const handleSaveChanges = async () => {
        setLoading(true);
        try {
            let payload: UserUpdate = {
                givenName: updatedUser.givenName,
                familyName: updatedUser.familyName,
                picture: updatedUser.picture,
                nickname: updatedUser.givenName,
                name: `${updatedUser.givenName} ${updatedUser.familyName}`,
            };

            // Include email in the payload if the user is not using social login
            if (!isSocialLogin) {
                payload.email = updatedUser.email;
            }

            if (selectedFile) {
                const newPictureURL = await uploadImage(selectedFile);
                if (newPictureURL) {
                    setUpdatedUser({ ...updatedUser, picture: newPictureURL });
                    payload.picture = newPictureURL;
                }
            }

            const token = await getAccessTokenSilently();
            await updateUser(token, payload);
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Profile updated successfully.' });
        } catch (error) {
            console.error("Error updating profile", error);
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error updating profile.' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="page-header">
                <h3>Profile</h3>
                <p>Manage your profile settings</p>
            </div>
            <Card>
                <div className="settings">
                    <div className="settings-row">
                        <label>Avatar</label>
                        <input type="file" onChange={handleFileSelect} style={{ display: 'none' }} id="file-upload" />
                        <label htmlFor="file-upload" className="max-w-5rem">
                            <div className="upload-logo-container">
                                <div className="workspace-logo-container">
                                    <img src={updatedUser.picture} alt={updatedUser.givenName} className="workspace-logo"/>
                                </div>
                                <div className="logo-upload">
                                    <span className="upload-text">Upload</span>
                                </div>
                            </div>
                        </label>
                        <small id="avatar-help">
                            Add or update your avatar.
                        </small>
                    </div>
                    <div className="settings-row">
                        <label htmlFor="givenName">Given Name</label>
                        <InputText
                            id="givenName"
                            value={updatedUser.givenName}
                            onChange={(e) => setUpdatedUser({ ...updatedUser, givenName: e.target.value })}
                        />
                    </div>
                    <div className="settings-row">
                        <label htmlFor="familyName">Family Name</label>
                        <InputText
                            id="familyName"
                            value={updatedUser.familyName}
                            onChange={(e) => setUpdatedUser({ ...updatedUser, familyName: e.target.value })}
                        />
                    </div>
                    {isSocialLogin ? (
                        <div className="settings-row">
                            <label htmlFor="email">Email</label>
                            <InputText
                                id="email"
                                value={updatedUser.email}
                                disabled
                            />
                            <small>Your email cannot be changed as you are signed in with a social account.</small>
                        </div>
                    ) : (
                        <div className="settings-row">
                            <label htmlFor="email">Email</label>
                            <InputText
                                id="email"
                                value={updatedUser.email}
                                onChange={(e) => setUpdatedUser({ ...updatedUser, email: e.target.value })}
                            />
                        </div>
                    )}
                    <div className="settings-row">
                        <Button label="Save Changes" onClick={handleSaveChanges} loading={loading}/>
                    </div>
                </div>
            </Card>
            {config.debugEnabled && (
                <Card>
                    <span>Debug details</span>
                    <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
                </Card>
            )}
        </div>
    );
};

export default withAuthenticationRequired(Profile, {
    onRedirecting: () => <Loading />,
});
