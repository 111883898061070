// context/WorkspaceContext.tsx
import React, {createContext, useState, useEffect} from 'react';
import { WorkspaceType } from '../types/Workspace';
import { useWorkspaces } from '../hooks/useWorkspaces';
import CreateWorkspaceDialog from "../components/CreateWorkspaceDialog";
import {useAuth0} from "@auth0/auth0-react";

interface WorkspaceContextType {
    workspace: WorkspaceType | null;
    setWorkspace: (workspace: WorkspaceType | null) => void;
    workspaces: WorkspaceType[];
    isLoadingWorkspaces: boolean;
}

const initialContextValue: WorkspaceContextType = {
    workspace: null,
    setWorkspace: () => {},
    workspaces: [],
    isLoadingWorkspaces: true,
};

export const WorkspaceContext = createContext<WorkspaceContextType>(initialContextValue);

interface WorkspaceProviderProps {
    children: React.ReactNode;
}

export const WorkspaceProvider: React.FC<WorkspaceProviderProps> = ({ children }) => {
    const [workspace, setWorkspace] = useState<WorkspaceType | null>(null);
    const { workspaces, isLoading: isLoadingWorkspaces } = useWorkspaces();
    const { error, isAuthenticated } = useAuth0();
    const [showCreateWorkspaceDialog, setShowCreateWorkspaceDialog] = useState(false);

    useEffect(() => {
        if (workspaces.length > 0) {
            const sortedWorkspaces = workspaces.sort((a, b) =>
                new Date(b.lastUsed).getTime() - new Date(a.lastUsed).getTime()
            );
            setWorkspace(sortedWorkspaces[0]);
        }
    }, [workspaces]);

    useEffect(() => {
        if (isAuthenticated && !workspace && !isLoadingWorkspaces) {
            setShowCreateWorkspaceDialog(true);
        } else {
            setShowCreateWorkspaceDialog(false);
        }
    }, [isAuthenticated, workspace, isLoadingWorkspaces]);

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    return (
        <WorkspaceContext.Provider value={{ workspace, setWorkspace, workspaces, isLoadingWorkspaces }}>
            {children}
            {showCreateWorkspaceDialog && showCreateWorkspaceDialog &&
                <CreateWorkspaceDialog onHide={() => setShowCreateWorkspaceDialog(false)} visible={showCreateWorkspaceDialog} />}
        </WorkspaceContext.Provider>
    );
};
