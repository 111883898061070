import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Pricing } from '../types/Pricing';

export const usePricing = () => {
    const [pricing, setPricing] = useState<Pricing[]>([]);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        const fetchPricing = async () => {
            try {
                const token = await getAccessTokenSilently();
                const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';
                const response = await fetch(`${apiUrl}/pricing`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data: Pricing[] = await response.json();
                setPricing(data)
            } catch (error) {
                console.error("Error fetching pricing data", error);
            }
        };

        fetchPricing();
    }, [isAuthenticated, getAccessTokenSilently]);

    return pricing;
};
