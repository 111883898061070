// utils/currencySymbols.tsx
export const currencySymbolsData: Record<string, string> = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    GBP: '£', // British Pound Sterling
    CAD: '$', // Canadian Dollar
    JPY: '¥', // Japanese Yen
    AUD: '$', // Australian Dollar
    CHF: 'Fr', // Swiss Franc
    INR: '₹', // Indian Rupee
    LKR: 'Rs', // Sri Lankan Rupee
    SGD: '$', // Singapore Dollar
    ILS: '₪', // Israeli New Sheqel
    KRW: '₩', // South Korean Won
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    THB: '฿', // Thai Baht
    BGN: 'Лв', // Bulgarian lev
    CZK: 'Kč', // Czech koruna
    COP: 'COL$', // Colombian Peso
    DKK: 'Kr', // Danish krone
    HUF: 'Ft', // Hungarian forint
    RON: 'lei', // Romanian leu
    SEK: 'kr', // Swedish krona
    ISK: 'Íkr', // Icelandic króna
    NOK: 'kr', // Norwegian krone
    HRK: 'kn', // Croatian kuna
    RUB: '₽', // Russian ruble
    TRY: '₺', // Turkish lira
    BRL: 'R$', // Brazilian Real
    CNY: '¥', // Renminbi
    HKD: '$', // Honk Kong Dollar
    IDR: 'Rp', // Indonesian rupiah
    MXN: '$', // Mexican peso
    MYR: 'RM', // Malaysian ringgit
    NZD: '$', // New Zealand dollar
    ZAR: 'R', // South African rand
    AED: 'AED', // United Arab Emirates Dirham
    AFN: ' ؋', // Afghan Afghani
    ALL: 'L', // Albanian Lek
    AMD: '֏', // Armenian Dram
    ANG: 'ƒ', // Netherlands Antillean Guilder
    AOA: 'Kz', // Angolan Kwanza
    ARS: '$', // Argentine Peso
    AWG: 'ƒ', // Aruban Florin
    AZN: 'AZN', // Azerbaijani Manat
    BAM: 'KM', // Bosnia-Herzegovina Convertible Mark
    BBD: 'Bds$', // Barbadian Dollar
    BDT: '৳', // Bangladeshi Taka
    BHD: 'BD', // Bahraini Dinar
    BIF: 'FBu', // Burundian Franc
    BMD: '$', // Bermudan Dollar
    BND: 'B$', // Brunei Dollar
    BOB: 'Bs.', // Bolivian Boliviano
    BSD: 'B$', // Bahamian Dollar
    BTC: '₿', // Bitcoin
    BTN: 'Nu.', // Bhutanese Ngultrum
    BWP: 'P', // Botswanan Pula
    BYN: 'BYN', // New Belarusian Ruble
    BYR: 'Br', // Belarusian Ruble
    BZD: 'BZ$', // Belize Dollar
    CDF: 'FC', // Congolese Franc
    CLF: 'CLF', // Chilean Unit of Account (UF)
    CLP: '$', // Chilean Peso
    CRC: '₡', // Costa Rican Colón
    CUC: 'CUC', // Cuban Convertible Peso
    CUP: '₱', // Cuban Peso
    CVE: 'CVE', // Cape Verdean Escudo
    DJF: 'DJF', // Djiboutian Franc
    DOP: 'RD$', // Dominican Peso
    DZD: 'DZD', // Algerian Dinar
    EGP: '£', // Egyptian Pound
    ERN: 'ERN', // Eritrean Nakfa
    ETB: 'ETB', // Ethiopian Birr
    FJD: '$', // Fijian Dollar
    FKP: '£', // Falkland Islands Pound
    GEL: 'GEL', // Georgian Lari
    GGP: '£', // Guernsey Pound
    GHS: '¢', // Ghanaian Cedi
    GIP: '£', // Gibraltar Pound
    GMD: 'GMD', // Gambian Dalasi
    GNF: 'GNF', // Guinean Franc
    GTQ: 'Q', // Guatemalan Quetzal
    GYD: '$', // Guyanaese Dollar
    HNL: 'L', // Honduran Lempira
    HTG: 'HTG', // Haitian Gourde
    IMP: '£', // Manx pound
    IQD: 'IQD', // Iraqi Dinar
    IRR: 'IRR', // Iranian Rial
    JEP: '£', // Jersey Pound
    JMD: 'J$', // Jamaican Dollar
    JOD: 'JOD', // Jordanian Dinar
    KES: 'KES', // Kenyan Shilling
    KGS: 'KGS', // Kyrgystani Som
    KHR: '៛', // Cambodian Riel
    KMF: 'KMF', // Comorian Franc
    KPW: '₩', // North Korean Won
    KWD: 'KWD', // Kuwaiti Dinar
    KYD: '$', // Cayman Islands Dollar
    KZT: 'лв', // Kazakhstani Tenge
    LAK: '₭', // Laotian Kip
    LBP: '£', // Lebanese Pound
    LRD: '$', // Liberian Dollar
    LSL: 'LSL', // Lesotho Loti
    LTL: 'LTL', // Lithuanian Litas
    LVL: 'LVL', // Latvian Lats
    LYD: 'LYD', // Libyan Dinar
    MAD: 'MAD', // Moroccan Dirham
    MDL: 'MDL', // Moldovan Leu
    MGA: 'MGA', // Malagasy Ariary
    MKD: 'ден', // Macedonian Denar
    MMK: 'MMK', // Myanma Kyat
    MNT: '₮', // Mongolian Tugrik
    MOP: 'MOP', // Macanese Pataca
    MRO: 'MRO', // Mauritanian Ouguiya
    MUR: '₨', // Mauritian Rupee
    MVR: 'MVR', // Maldivian Rufiyaa
    MWK: 'MWK', // Malawian Kwacha
    MZN: 'MT', // Mozambican Metical
    NAD: '$', // Namibian Dollar
    NGN: '₦', // Nigerian Naira
    NIO: 'C$', // Nicaraguan Córdoba
    NPR: '₨', // Nepalese Rupee
    OMR: 'OMR', // Omani Rial
    PAB: 'B/.', // Panamanian Balboa
    PEN: 'S/.', // Peruvian Nuevo Sol
    PGK: 'PGK', // Papua New Guinean Kina
    PKR: '₨', // Pakistani Rupee
    PYG: 'Gs', // Paraguayan Guarani
    QAR: 'QAR', // Qatari Rial
    RSD: 'Дин.', // Serbian Dinar
    RWF: 'RWF', // Rwandan Franc
    SAR: 'SAR', // Saudi Riyal
    SBD: '$', // Solomon Islands Dollar
    SCR: '₨', // Seychellois Rupee
    SDG: 'SDG', // Sudanese Pound
    SHP: '£', // Saint Helena Pound
    SLL: 'SLL', // Sierra Leonean Leone
    SOS: 'S', // Somali Shilling
    SRD: '$', // Surinamese Dollar
    STD: 'STD', // São Tomé and Príncipe Dobra
    SVC: 'SVC', // Salvadoran Colón
    SYP: '£', // Syrian Pound
    SZL: 'SZL', // Swazi Lilangeni
    TJS: 'TJS', // Tajikistani Somoni
    TMT: 'TMT', // Turkmenistani Manat
    TND: 'TND', // Tunisian Dinar
    TOP: 'TOP', // Tongan Paʻanga
    TTD: 'TT$', // Trinidad and Tobago Dollar
    TWD: 'TWD', // New Taiwan Dollar
    TZS: 'TZS', // Tanzanian Shilling
    UAH: '₴', // Ukrainian Hryvnia
    UGX: 'UGX', // Ugandan Shilling
    UYU: '$U', // Uruguayan Peso
    UZS: 'лв', // Uzbekistan Som
    VEF: 'Bs', // Venezuelan Bolívar Fuerte
    VND: '₫', // Vietnamese Dong
    VUV: 'VUV', // Vanuatu Vatu
    WST: 'WST', // Samoan Tala
    XAF: 'XAF', // CFA Franc BEAC
    XAG: 'XAG', // Silver (troy ounce)
    XAU: 'XAU', // Gold (troy ounce)
    XCD: '$', // East Caribbean Dollar
    XDR: 'XDR', // Special Drawing Rights
    XOF: 'XOF', // CFA Franc BCEAO
    XPF: 'XPF', // CFP Franc
    YER: 'YER', // Yemeni Rial
    ZMK: 'ZMK', // Zambian Kwacha (pre-2013)
    ZMW: 'ZMW', // Zambian Kwacha
    ZWL: 'Z$', // Zimbabwean Dollar
};

export const CurrencySymbols = new Proxy(currencySymbolsData, {
    get: (target, name: string) => target[name.toUpperCase()] || target[name.toLowerCase()]
});