// components/DocumentsMenu.tsx
import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Document } from '../types/Document';

interface DocumentsMenuProps {
    document: Document;
    onSubmit: (documentId: number) => void;
}

const DocumentsMenu: React.FC<DocumentsMenuProps> = ({ document, onSubmit }) => {
    const menu = useRef<Menu>(null);

    const items = [
        {
            label: 'Submit for approval',
            command: () => onSubmit(document.documentId)
        },
    ];

    return (
        <div className="member-actions">
            <span className="pi pi-ellipsis-h" onClick={(e) => menu.current?.toggle(e)}></span>
            <Menu model={items} popup ref={menu} id={`popup_menu_${document.documentId}`}/>
        </div>
    );
};

export default DocumentsMenu;
