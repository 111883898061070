// Workspace.tsx
import React, {useContext, useState, useEffect, useRef} from "react";
import { WorkspaceContext } from '../context/WorkspaceContext';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useWorkspaces} from "../hooks/useWorkspaces";
import { WorkspaceTypeUpdate } from "../types/Workspace";
import { Toast } from 'primereact/toast';
import { useUploadImage } from "../hooks/useUploadImage";
export const Workspace = () => {
    const { workspace: selectedWorkspace } = useContext(WorkspaceContext);
    const { updateWorkspace } = useWorkspaces();
    const toast = useRef<Toast>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [updatedWorkspace, setUpdatedWorkspace] = useState<WorkspaceTypeUpdate>({
        workspaceId: 0,
        workspaceName: '',
        logoURL: '',
    });
    const { getUploadURL, uploadImage, uploadUrls } = useUploadImage();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    // Update state when selectedWorkspace changes
    useEffect(() => {
        if (selectedWorkspace) {
            setUpdatedWorkspace({
                workspaceId: selectedWorkspace.workspaceId,
                workspaceName: selectedWorkspace.workspaceName,
                logoURL: selectedWorkspace.logoURL,
            });
        }
    }, [selectedWorkspace]);

    useEffect(() => {
        const uploadProcess = async () => {
            if (selectedFile) {
                const newLogoURL = await uploadImage(selectedFile);
                console.log("Uploaded image URL:", newLogoURL);

                if (newLogoURL) {
                    const updatedWorkspaceInfo = { ...updatedWorkspace, logoURL: newLogoURL };
                    await updateWorkspace(updatedWorkspaceInfo);
                    toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Logo updated successfully.' });
                    setSelectedFile(null); // Reset selectedFile to null after upload
                } else {
                    console.error("Error during file upload");
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error uploading logo.' });
                }
            }
        };

        if (uploadUrls.uploadURL && selectedFile) {
            uploadProcess();
        }
    }, [uploadUrls, selectedFile, uploadImage, updateWorkspace, updatedWorkspace]);


    // If the selected workspace is not yet loaded, return a loading indicator or null
    if (!selectedWorkspace) {
        return <div>Loading...</div>;
    }

    // Use the selected workspace for displaying information
    const workspace = selectedWorkspace;

    const handleSaveChanges = async () => {
        setIsSaving(true);
        try {
            await updateWorkspace(updatedWorkspace);
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Workspace updated successfully.' });
        } catch (error) {
            console.error("Error saving workspace", error);
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error updating workspace.' });
        } finally {
            setIsSaving(false);
        }
    };
    // Function to handle file selection and upload
    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log("Selected file:", file);
            setSelectedFile(file);
            await getUploadURL(file.name, file.type, "workspace-logos");
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="page-header">
                <h3>Workspace</h3>
                <p>Manage your workspace settings</p>
            </div>
            <Card>
                <div className="settings">
                    <div className="settings-row">
                        <label>Logo</label>
                        <input type="file" onChange={handleFileSelect} style={{ display: 'none' }} id="file-upload" />
                        <label htmlFor="file-upload" className="max-w-5rem">
                            <div className="upload-logo-container">
                                <div className="workspace-logo-container">
                                    <img src={workspace.logoURL} alt={workspace.workspaceName} className="workspace-logo" />
                                </div>
                                <div className="logo-upload">
                                    <span className="upload-text">Upload</span>
                                </div>
                            </div>
                        </label>
                        <small id="logo-help">
                            Add a logo for your workspace. Recommended size is 256x256px.
                        </small>
                    </div>
                    <div className="settings-row flex flex-column gap-2 max-w-30rem">
                        <label htmlFor="workspaceName">Workspace name</label>
                        <InputText
                            id="workspaceName"
                            value={updatedWorkspace.workspaceName}
                            onChange={(e) => setUpdatedWorkspace({ ...updatedWorkspace, workspaceName: e.target.value })}
                        />
                    </div>
                    <div className="settings-row">
                        <Button label="Save Changes" onClick={handleSaveChanges} loading={isSaving} disabled={isSaving}/>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default withAuthenticationRequired(Workspace, {
    onRedirecting: () => <Loading />,
});
