// views/Plan.tsx
import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { WorkspaceContext } from '../context/WorkspaceContext';
import '../assets/styles/plan.scss';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';
import check from '../assets/icons/check.svg';
import card from '../assets/icons/card.svg';
import refresh from '../assets/icons/refresh.svg';
import shield from '../assets/icons/shield.svg';
import { usePricing } from '../hooks/usePricing';
import { CurrencySymbols } from "../utils/currencySymbols";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { useStripeCheckout } from '../hooks/useStripeCheckout';

// Constants for pricing keys
const PRICING_KEYS = {
    professionalMonthly: 'professional_monthly',
    professionalYearly: 'professional_yearly',
    additionalCreditsMonthly: 'additional_credits_monthly',
    additionalCreditsYearly: 'additional_credits_yearly',
    additionalUserMonthly: 'additional_user_monthly',
    additionalUserYearly: 'additional_user_yearly',
};
type PlanSelection = 'monthly' | 'yearly';

const Plan: React.FC = () => {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();
    const { workspace } = useContext(WorkspaceContext);
    const pricingData = usePricing();
    const planOptions = [
        {label: 'Yearly', value: 'yearly'},
        {label: 'Monthly', value: 'monthly'}
    ];
    const { createCheckoutSession, isLoading: isCheckoutLoading, error: checkoutError } = useStripeCheckout();

    const [basePlanCosts, setBasePlanCosts] = useState({ monthly: 0, yearly: 0 });
    const [currencyCode, setCurrencyCode] = useState('USD');
    const [planSelection, setPlanSelection] = useState<PlanSelection>('yearly');
    const [users, setUsers] = useState<number>(0);
    const [credits, setCredits] = useState<number>(0);
    const [creditCosts, setCreditCosts] = useState(0);
    const [userCosts, setUserCosts] = useState(0);

    // Function to calculate days until trial end
    const getDaysUntilTrialEnd = (trialEndDate: string) => {
        const trialEnd = new Date(trialEndDate);
        const now = new Date();
        const timeDiff = trialEnd.getTime() - now.getTime();
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    };

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const handleSelectChange = (e: any) => {
        // If the new plan is the same as the current, ignore the change
        if (e.value === planSelection) {
            return;
        }
        setPlanSelection(e.value);
    };

    useEffect(() => {
        const monthlyPlan = pricingData.find(p => p.key === PRICING_KEYS.professionalMonthly);
        const yearlyPlan = pricingData.find(p => p.key === PRICING_KEYS.professionalYearly);

        if (monthlyPlan && yearlyPlan) {
            setBasePlanCosts({
                monthly: monthlyPlan.unitAmount / 100,
                yearly: yearlyPlan.unitAmount / 100
            });
            setCurrencyCode(monthlyPlan.currency.toUpperCase());
        }
    }, [pricingData]);

    useEffect(() => {
        const calculateAdditionalCosts = () => {
            const creditKey = planSelection === 'yearly' ? PRICING_KEYS.additionalCreditsYearly : PRICING_KEYS.additionalCreditsMonthly;
            const userKey = planSelection === 'yearly' ? PRICING_KEYS.additionalUserYearly : PRICING_KEYS.additionalUserMonthly;

            const creditPricing = pricingData.find(pricing => pricing.key === creditKey);
            const userPricing = pricingData.find(pricing => pricing.key === userKey);

            if (creditPricing && userPricing) {
                setUserCosts(users * userPricing.unitAmount / 100);
                setCreditCosts(credits * creditPricing.unitAmount / 5000);
            }
        };

        if (workspace && pricingData.length > 0) {
            calculateAdditionalCosts();
        }
    }, [workspace, pricingData, users, credits, planSelection]);

    const totalCost = basePlanCosts[planSelection] + userCosts + creditCosts;

    const handleCheckout = async () => {
        if (!workspace) {
            console.error('Workspace is null, cannot proceed with checkout.');
            // Optionally, show an error message to the user or handle this case as appropriate for your app
            return;
        }
        const items = [
            // Add the base professional plan with a quantity of 1
            { priceId: planSelection === 'yearly' ? PRICING_KEYS.professionalYearly : PRICING_KEYS.professionalMonthly, quantity: 1 },
        ];

        // If additional users are added, include them
        if (users > 0) {
            items.push({ priceId: planSelection === 'yearly' ? PRICING_KEYS.additionalUserYearly : PRICING_KEYS.additionalUserMonthly, quantity: users });
        }

        // If additional credits are added, include them
        if (credits > 0) {
            items.push({ priceId: planSelection === 'yearly' ? PRICING_KEYS.additionalCreditsYearly : PRICING_KEYS.additionalCreditsMonthly, quantity: credits / 50 });
        }
        console.log("Sending to Stripe:",items);

        // Invoke the checkout session creation with the prepared items
        await createCheckoutSession(items, workspace.workspaceId);
    };

    return (
        <div>
            {workspace && (
                <div className="plan-page">
                    <div className="mt-4">
                        {workspace.status === 'active' ? (<p className="info">MANAGE PLAN</p>) : (<p className="info">TRIAL</p>)}
                        {workspace.status === 'active' ? (<p className="heading">Simple, transparent pricing</p>) : (<p className="heading">{new Date(workspace.trialEnd) > new Date() ? "Don't lose your access!" : "Your trial ended :("}</p>)}
                        {workspace.status === 'active' ? (<p className="light-text">We like to keep things simple.</p>) : (new Date(workspace.trialEnd) > new Date() ? <p className="warning">Your trial ends in {getDaysUntilTrialEnd(workspace.trialEnd)} days</p> : <p className="light-text">Subscribe now to continue using Mambo AI for your compliance needs</p>)}
                    </div>
                    <div className="mambo-card-container mt-6">
                        <div className="left-half">
                            <div className="text-left">
                                <div className="font-semibold pb-2">Professional Plan</div>
                                <div className="flex pricing-row">
                                    <div>
                                        Includes 2 users and 100 credits
                                    </div>
                                    <div className="font-semibold">
                                        {CurrencySymbols[currencyCode]}{(planSelection === 'yearly' ?
                                        (basePlanCosts.yearly / 12) : basePlanCosts.monthly).toFixed(2)}
                                    </div>
                                </div>
                                <div className="font-semibold pb-2 pt-4">Additional users</div>
                                <div className="flex pricing-row">
                                <div>
                                        <InputNumber value={users}
                                                     onValueChange={(e) => setUsers(e.value ?? 2)}
                                                     showButtons
                                                     inputClassName="number-toggle"
                                                     decrementButtonClassName="number-toggle-minus"
                                                     incrementButtonClassName="number-toggle-plus"
                                                     decrementButtonIcon="pi pi-minus"
                                                     incrementButtonIcon="pi pi-plus"
                                                     allowEmpty={false}
                                                     min={0}
                                                     step={1}
                                        />
                                    </div>
                                    <div>
                                        {planSelection === 'yearly' ?
                                            <span
                                                className="font-semibold">{CurrencySymbols[currencyCode]}{(userCosts / 12).toFixed(2).toLocaleString()}</span> :
                                            <span
                                                className="font-semibold">{CurrencySymbols[currencyCode]}{(userCosts).toFixed(2).toLocaleString()}</span>
                                        }
                                    </div>
                                </div>
                                <div className="font-semibold pb-2 pt-4">Additional credits</div>
                                <div className="flex pricing-row">
                                    <div>
                                        <InputNumber value={credits}
                                                     onValueChange={(e) => setCredits(e.value ?? 100)}
                                                     showButtons
                                                     inputClassName="number-toggle"
                                                     decrementButtonClassName="number-toggle-minus"
                                                     incrementButtonClassName="number-toggle-plus"
                                                     decrementButtonIcon="pi pi-minus"
                                                     incrementButtonIcon="pi pi-plus"
                                                     allowEmpty={false}
                                                     min={0}
                                                     step={50}
                                        />
                                    </div>
                                    <div>
                                        {planSelection === 'yearly' ?
                                            <span
                                                className="font-semibold">{CurrencySymbols[currencyCode]}{(creditCosts / 12).toFixed(2).toLocaleString()}</span> :
                                            <span
                                                className="font-semibold">{CurrencySymbols[currencyCode]}{(creditCosts).toFixed(2).toLocaleString()}</span>
                                        }
                                    </div>
                                </div>
                                <Divider className="pt-6 mb-0"/>
                                <div className="flex justify-content-between">
                                    <span>Monthly costs ({users + 2} users and {credits + 100} credits)</span>
                                    {planSelection === 'yearly' ?
                                        <span
                                            className="font-semibold text-xl">{CurrencySymbols[currencyCode]}{((+totalCost / 12)).toFixed(2).toLocaleString()}</span> :
                                        <span
                                            className="font-semibold text-xl">{CurrencySymbols[currencyCode]}{(+totalCost).toFixed(2).toLocaleString()}</span>
                                    }
                                </div>
                                <div className="font-bold mt-3">What's included</div>
                                <ul className="custom-svg-list">
                                    <li><img src={check} alt="check"/> Unlimited documents</li>
                                    <li><img src={check} alt="check"/> Approval workflow management</li>
                                    <li><img src={check} alt="check"/> User roles</li>
                                    <li><img src={check} alt="check"/> Priority support</li>
                                </ul>
                            </div>
                        </div>
                        <div className="right-half">
                            <div className="selection-container">
                                <div className="tooltip-container">
                                    <span className="tooltip-always-on">1 free month</span>
                                    <div className="tooltip-caret"></div>
                                </div>
                                <SelectButton value={planSelection} onChange={handleSelectChange} options={planOptions}
                                              allowEmpty={false}/>
                            </div>
                            <div className="price-quote">
                                {planSelection === 'yearly' ?
                                    <>
                                        <span className="amount">{CurrencySymbols[currencyCode]}{(+totalCost / 12).toFixed(2).toLocaleString()}<span>/mo</span></span>
                                        <span className="annual">{CurrencySymbols[currencyCode]}{(+totalCost).toFixed(2).toLocaleString()} billed annually</span>
                                    </>
                                    :
                                    <>
                                        <span className="amount">{CurrencySymbols[currencyCode]}{(+totalCost).toFixed(2).toLocaleString()}<span>/mo</span></span>
                                        <span className="annual">{CurrencySymbols[currencyCode]}{(+totalCost).toFixed(2).toLocaleString()} billed monthly</span>
                                    </>
                                }
                                <span className="cancel">Cancel anytime.</span>
                            </div>
                            <Button label="Checkout" className="mambo-button" onClick={handleCheckout} disabled={isCheckoutLoading}/>
                            {checkoutError && <p className="error">{checkoutError}</p>}
                            <div className="disclosure">
                        <p>Your subscription will automatically renew unless you cancel before the next renewal date. <a href="https://www.mamboai.com/terms">Canceling is easy.</a></p>
                                <p>By purchasing, you agree to our <a href="https://www.mamboai.com/terms" target="_blank" rel="noreferrer">Terms of Service.</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="flex subscribe-extras">
                        <div className="flex"><img src={shield} alt="shield icon" /><span>Choose between monthly or yearly plans</span></div>
                        <div className="flex ml-4"><img src={card} alt="card icon" /><span>No setup or implementation fees</span></div>
                        <div className="flex ml-4"><img src={refresh} alt="refresh icon" /><span>Easy to cancel or change plans</span></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withAuthenticationRequired(Plan, {
    onRedirecting: () => <Loading />,
});