// useStripeCheckout.ts
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { loadStripe } from '@stripe/stripe-js';

export const useStripeCheckout = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const createCheckoutSession = async (items: Array<{ priceId: string; quantity: number }>, workspaceId: number) => {
        setIsLoading(true);
        setError(null);
        try {
            const token = await getAccessTokenSilently();
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';
            const response = await fetch(`${apiUrl}/stripeCreateCheckout`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ items, workspaceId }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const { sessionId } = await response.json();
            const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
            const stripe = await stripePromise;
            await stripe?.redirectToCheckout({ sessionId });
        } catch (error) {
            console.error("Error creating checkout session", error);
            setError('Failed to initiate checkout. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return { createCheckoutSession, isLoading, error };
};
