import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useUploadImage = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [uploadUrls, setUploadUrls] = useState({ uploadURL: '', fileURL: '' });
    const [error, setError] = useState<string | null>(null);

    const getUploadURL = useCallback(async (fileName: string, fileType: string, folderName: string) => {
        if (!isAuthenticated) {
            setError('Not authenticated');
            return;
        }

        try {
            const token = await getAccessTokenSilently();
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';

            let response = await fetch(`${apiUrl}/imageUpload`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ fileName, fileType, folderName }),
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Hook response from getUploadURL:", data);
                setUploadUrls({ uploadURL: data.uploadURL, fileURL: data.fileURL });
            } else {
                // Handle HTTP errors
                const errorData = await response.json();
                setError(errorData.message || `HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error("Error getting upload URL", error);
            setError('Error getting upload URL');
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    // Function to upload image to S3 using the signed URL
    const uploadImage = useCallback(async (file: File) => {
        if (!uploadUrls.uploadURL) {
            setError('Upload URL not available');
            return;
        }

        try {
            const response = await fetch(uploadUrls.uploadURL, {
                method: 'PUT',
                body: file,
                headers : {
                    'Content-Type': file.type,
                }
            });

            if (response.ok) {
                // The file URL is where the image is accessible after upload
                return uploadUrls.fileURL;
            } else {
                // Handle HTTP errors
                const responseText = await response.text();
                console.error("HTTP error during upload:", response.status, response.statusText, responseText);
                setError(`HTTP error! status: ${response.status}`);
                return null;
            }
        } catch (error) {
            console.error('Error uploading to S3:', error);
            setError('Error uploading image');
            return null;
        }
    }, [uploadUrls]);

    return { getUploadURL, uploadImage, uploadUrls, error };
};
