import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useRequestApproval = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [approvalResult, setApprovalResult] = useState<string | null>(null);

    const requestApproval = useCallback(async (documentId: number) => {
        if (!isAuthenticated) {
            setError('Not authenticated');
            return;
        }

        setIsLoading(true);
        try {
            const token = await getAccessTokenSilently();
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';

            let response = await fetch(`${apiUrl}/requestApproval`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ documentId }),
            });

            if (response.ok) {
                const data = await response.json();
                setApprovalResult(data.message);
            } else {
                const errorData = await response.json();
                setError(errorData.message || `HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error("Error requesting document approval", error);
            setError('Error requesting approval');
        } finally {
            setIsLoading(false);
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    return { requestApproval, approvalResult, error, isLoading };
};
