import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { WorkspaceInvite } from '../types/WorkspaceInvite';

export const useWorkspaceInvite = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [inviteResult, setInviteResult] = useState(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const sendWorkspaceInvite = useCallback(async (inviteeEmail: string, workspaceId: number, memberRole: string, permissions: string[] = []) => {
        if (!isAuthenticated) {
            setError('Not authenticated');
            return;
        }

        setIsLoading(true);
        try {
            const token = await getAccessTokenSilently();
            const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';

            const bodyData: WorkspaceInvite = { inviteeEmail, workspaceId, memberRole };
            if (permissions.length > 0) {
                bodyData.permissions = permissions;
            }

            let response = await fetch(`${apiUrl}/workspaceInvite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(bodyData),
            });

            if (response.ok) {
                const data = await response.json();
                setInviteResult(data.message);
            } else {
                const errorData = await response.json();
                setError(errorData.message || `HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error("Error sending workspace invite", error);
            setError('Error sending invite');
        } finally {
            setIsLoading(false);
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    return { sendWorkspaceInvite, inviteResult, error, isLoading };
};
