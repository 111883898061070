import React, { Component } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";

const registeredLanguages: { [key: string]: boolean } = {};

interface HighlightProps {
  language?: string;
  children: React.ReactNode;
}

interface HighlightState {
  loaded: boolean;
}

class Highlight extends Component<HighlightProps, HighlightState> {
  private readonly codeNode: React.RefObject<HTMLElement>;

  constructor(props: HighlightProps) {
    super(props);
    this.state = { loaded: false };
    this.codeNode = React.createRef();
  }

  componentDidMount() {
    const { language } = this.props;

    if (language && !registeredLanguages[language]) {
      try {
        const newLanguage = require(`highlight.js/lib/languages/${language}`);
        hljs.registerLanguage(language, newLanguage);
        registeredLanguages[language] = true;

        this.setState({ loaded: true }, this.highlight);
      } catch (e) {
        console.error(e);
        throw new Error(`Cannot register the language ${language}`);
      }
    } else {
      this.setState({ loaded: true });
    }
  }

  componentDidUpdate() {
    this.highlight();
  }

  highlight = () => {
    if (this.codeNode.current) {
      hljs.highlightBlock(this.codeNode.current);
    }
  };

  render() {
    const { language, children } = this.props;
    const { loaded } = this.state;

    if (!loaded) {
      return null;
    }

    return (
        <pre className="rounded">
        <code ref={this.codeNode} className={language || ''}>
          {children}
        </code>
      </pre>
    );
  }
}

export default Highlight;
