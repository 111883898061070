// MemberItem.tsx
import React, {useRef, useCallback, useMemo} from 'react';
import {Member} from "../types/Member";
import {Menu} from "primereact/menu";
import { useMembers } from '../hooks/useMembers';
import { Toast } from 'primereact/toast';
interface MemberItemProps {
    member: Member;
}

const MemberItem: React.FC<MemberItemProps & { onMemberUpdate: (member: Member) => void }> = ({ member, onMemberUpdate }) => {
    const { updateMember } = useMembers();
    const menu = useRef<Menu>(null);
    const toast = useRef<Toast>(null);

    const handleMemberUpdate = useCallback(async (newStatus: string, newRole: string, newPermissions: string[]) => {
        try {
            const updatedMember = {
                ...member,
                memberStatus: newStatus,
                memberRole: newRole,
                permissions: newPermissions,
            };

            await updateMember(updatedMember);
            onMemberUpdate(updatedMember);
            toast.current?.show({ severity: 'success', summary: 'Update Successful', detail: 'Member details updated successfully.' }); // Success message
        } catch (error) {
            toast.current?.show({ severity: 'error', summary: 'Update Failed', detail: 'Error updating member details.' }); // Error message
        }
    }, [member, onMemberUpdate, updateMember, toast]);

    const handlePermissionsUpdate = useCallback(() => {
        const currentPermissions = member.permissions || [];
        const hasApprover = currentPermissions.includes('Approver');
        const newPermissions = hasApprover
            ? currentPermissions.filter(permission => permission !== 'Approver')
            : [...currentPermissions, 'Approver'];

        handleMemberUpdate(member.memberStatus, member.memberRole, newPermissions);
    }, [member.permissions, handleMemberUpdate, member.memberRole, member.memberStatus]);

    const menuItems = useMemo(() => [
        {
            label: member.memberRole === 'Admin' ? 'Downgrade to user' : 'Upgrade to admin',
            command: () => handleMemberUpdate(member.memberStatus, member.memberRole === 'Admin' ? 'User' : 'Admin', member.permissions)
        },
        {
            label: member.permissions.includes('Approver') ? 'Revoke approver permission' : 'Grant approver permission',
            command: handlePermissionsUpdate
        },
        {
            label: member.memberStatus === 'Active' ? 'Suspend member' : 'Activate member',
            command: () => handleMemberUpdate(member.memberStatus === 'Active' ? 'Suspended' : 'Active', member.memberRole, member.permissions)
        },
    ], [member, handleMemberUpdate, handlePermissionsUpdate]);

    const toggleMenu = (event: React.MouseEvent) => {
        menu.current?.toggle(event);
    };

    const getInitials = (email: string) => {
        return email ? email.substring(0, 2).toUpperCase() : '';
    };

    const displayPicture = member.memberPicture ? (
            <img src={member.memberPicture} alt={member.memberName} className="member-image" />
        ) : (
            <div className="member-placeholder">{getInitials(member.memberEmail)}</div>
        );

        // Function to render status
        const renderStatusPill = () => {
            if (member.memberStatus === 'Invited') {
                return <span className="status-pill invited">Invited</span>;
            }
            return null;
        };

        // Function to render permissions as pills
        const renderPermissions = () => {
            if (member.permissions && member.permissions.length > 0) {
                // Filter out empty strings before mapping
                const validPermissions = member.permissions.filter(permission => permission.trim() !== '');
                if (validPermissions.length > 0) {
                    return validPermissions.map((permission, index) => (
                        <span key={index} className="status-pill permissions">{permission}</span>
                    ));
                }
            }
            return null; // Return null if permissions are null, empty, or only contain empty strings
        };

        return (
            <div className="member-item col-12">
                <Toast ref={toast} />
                <div className="member-left">
                    <div className="member-pic">
                        {displayPicture}
                    </div>
                    <div className="member-contact">
                        <div className="member-name">{member.memberName}</div>
                        <div className="member-email">{member.memberEmail}</div>
                    </div>
                    {member.memberStatus === 'Suspended' ? (
                        <div className="member-status-suspended">Suspended</div>
                    ) : (
                        <div className="member-role">
                            {member.memberRole}
                            {renderStatusPill()}
                            {renderPermissions()}
                        </div>
                    )}
                </div>
                <div className="member-right col-1">
                    <div className="member-actions">
                        <span className="pi pi-ellipsis-h" onClick={toggleMenu}></span>
                        <Menu model={menuItems} popup popupAlignment="right" ref={menu} />
                    </div>
                </div>
            </div>
        );
};

export default MemberItem;
