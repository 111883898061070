import React, {useRef, useState} from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { useWorkspaceInvite } from '../hooks/useWorkspaceInvite';

interface InviteMemberDialogProps {
    visible: boolean;
    onHide: () => void;
    onInvite: (email: string, roles: string[]) => void;
    workspaceId: number;
    onInviteSuccess: () => void;
}

const InviteMemberDialog: React.FC<InviteMemberDialogProps> = ({ visible, onHide, workspaceId, onInviteSuccess }) => {
    const [email, setEmail] = useState<string>('');
    const [role, setRole] = useState<string>('User');
    const [checked, setChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { sendWorkspaceInvite } = useWorkspaceInvite();
    const toast = useRef<Toast>(null);
    const roleOptions = [
        { label: 'User', value: 'User' },
        { label: 'Admin', value: 'Admin' }
    ];

    const handleRolesChange = (e: { value: string }) => {
        setRole(e.value);
    };

    const handleInviteClick = async () => {
        setLoading(true);
        let permissions = [];
        if (checked) {
            permissions.push('Approver');
        }

        try {
            await sendWorkspaceInvite(email, workspaceId, role, permissions);
            setEmail('');
            setRole('User');
            setChecked(false);
            onHide();
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Invitation sent successfully', life: 3000 });
            onInviteSuccess();
        } catch (error) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to send invitation', life: 3000 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
        <Toast ref={toast} />
            <Dialog header="Invite to your workspace" visible={visible} style={{ width: '50vw' }} onHide={onHide}>
            <div className="p-fluid">
                <div className="p-field settings-row">
                    <label htmlFor="email">Email</label>
                    <InputText id="email" value={email} placeholder="email@example.com" onChange={(e) => setEmail(e.currentTarget.value)} />
                </div>
                <div className="p-field settings-row">
                    <label htmlFor="roles">Role</label>
                    <Dropdown id="roles" value={role} options={roleOptions} onChange={handleRolesChange} placeholder="Select role" />
                </div>
                <div className="p-field settings-row">
                    <label htmlFor="roles">Extra permission</label>
                    <Checkbox
                        id="extraPermission"
                        onChange={e => setChecked(e.checked ?? false)}
                        checked={checked}
                    ></Checkbox>
                    <span className="ml-2">Can approve documents</span>
                </div>
            </div>
            <Button label="Send invite" onClick={handleInviteClick} loading={loading}/>
        </Dialog>
        </>
    );
};

export default InviteMemberDialog;
