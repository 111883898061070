// hooks/useDocuments.ts
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Document } from '../types/Document';

export const useDocuments = (workspaceId: number | undefined) => {
    const [documents, setDocuments] = useState<Document[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated || !workspaceId) {
            setIsLoading(false);
            return;
        }

        const fetchDocuments = async () => {
            setIsLoading(true);
            try {
                const token = await getAccessTokenSilently();
                const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';
                const response = await fetch(`${apiUrl}/documents?workspaceId=${workspaceId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data: Document[] = await response.json();
                setDocuments(data);
            } catch (error) {
                console.error("Error fetching from API", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDocuments();
    }, [isAuthenticated, getAccessTokenSilently, workspaceId]);

    return { documents, isLoading, setDocuments };
};
