import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Billing } from '../types/Billing';
export const useBilling = (workspaceId: number) => {
    const [billing, setBilling] = useState<Billing[]>([]);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        const fetchBilling = async () => {
            try {
                const token = await getAccessTokenSilently();
                const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8888';
                const response = await fetch(`${apiUrl}/billing?workspaceId=${workspaceId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data: Billing[] = await response.json();
                setBilling(data)
            } catch (error) {
                console.error("Error fetching billing data", error);
            }
        };

        fetchBilling();
    }, [isAuthenticated, getAccessTokenSilently, workspaceId]);

    return billing;
};
